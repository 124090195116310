import React from "react";
//import { graphql } from 'gatsby'; 
import Link from 'gatsby-link';
//import ReactHtmlParser from 'react-html-parser';

import SEO from "../../utils/seo";
import Layout from "../../layout/layout";
import PaginationLinks from "../../components/PaginationLinks";
import ContentContainer from "../../components/ContentContainer";


class BlogIndex extends React.Component {
  constructor(props) {
    super(props);    
    this.pageContext = this.props.pageContext;
  }

  renderFeaturedImage(featured_image, path) {
    if(featured_image) {
      return(
        <Link to={path}><img src={featured_image} className="img-fluid img-thumbnail rounded" alt="" /></Link>
      );
    }     
  }

  renderBlogPost(node, blogPath) {    
    const { title, slug, date, featured_image, excerpt } = node.childMarkdownRemark.frontmatter;
    const path = `${blogPath}/${slug}`;
    return(
      <div className="blog-post-item pt-4">
        { this.renderFeaturedImage(featured_image, path) }
        <h3><Link to={path}>{title}</Link></h3>
        <p>{date}</p>        
        <p>{excerpt}</p>        
      </div>      
    );
  }

  render(){
    const { blogTitle, postsForPage, blogPath, page, pages } = this.pageContext;

    return(
      <Layout>
        <SEO title="Blog" />
        <ContentContainer heading={blogTitle}>

          <div className="row">
            {postsForPage.map((node, index) => (
              <div className="col-md-6" key={`blog-page${page}-post-${index}`}> 
                { this.renderBlogPost(node, blogPath) }
              </div>
            ))}
          </div>
          
          <PaginationLinks pagePath={blogPath} numPages={pages} currentPage={page} />

        </ContentContainer>
      </Layout>
    );
  }
}

export default BlogIndex;