import React from "react";
import Link from 'gatsby-link';

const createPaginationObjects = (pagePath, length, currentPage, increment = 2) =>
	Array.from({ length }, (_, i) => ({
		link: `/${pagePath}/${i + increment}/`,
		index: i + increment,
		current: currentPage === i + increment,
	}));

const PaginationLinks = ({pagePath, numPages, currentPage, prev, next}) => {
  
  // Create the navigation link
	let navItems = [
		{
			link: pagePath,
			index: 1,
			current: currentPage === 1,
		},
	];

  if (numPages <= 5) {
		navItems = [
			...navItems,
			...Array.from({ length: numPages - 1 }, (_, i) => ({
				link: `${pagePath}/${i + 2}`,
				index: i + 2,
				current: currentPage === i + 2, 
			})),
		];
	} else {
		// We have a situation where we have to show the first
		// item, three items around the current one
		// and also the last item
		/* eslint-disable no-lonely-if */
		if (currentPage <= 3) {
			// If the current one is closer to the start
			navItems = [
				...navItems,
				...createPaginationObjects(pagePath, 3, currentPage),
				{
					separator: true,
					index: 'starter-separator',
				},
				{
					link: `${pagePath}/${numPages}`,
					index: numPages,
					current: false,
				},
			];
		} else if (currentPage > numPages - 3) {
			// If the current one is closer to the last one
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'finisher-separator',
				},
				...createPaginationObjects(pagePath, 4, currentPage, numPages - 3),
			];
		} else {
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'starter-separator',
				},
				...createPaginationObjects(pagePath, 3, currentPage, currentPage - 1),
				{
					separator: true,
					index: 'finisher-separator',
				},
				{
					link: `${pagePath}/${numPages}`,
					index: numPages,
					current: false,
				},
			];
		}		
	}

  if(numPages > 1) {
    return(
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {prev && (
            <li className="page-item">
              <Link to={prev} className="page-link" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </Link>
            </li>
          )}
  
          {navItems.map(item => (
            <li className="page-item" key={item.index}>
              <Link to={item.link} className={`page-link ${ item.current ? 'is-current' : '' }`} aria-label={`Goto page ${item.index}`} >
                {item.index}
              </Link>
            </li>
          ))}
  
          {next && (
            <li className="page-item">
              <Link to={next} className="page-link" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    );
    
  } else {
    return false;
  }  
};
 
export default PaginationLinks;